import { getUrlKey, getGMT8Date } from '@/apis/utils';
import { getActivity } from '@/apis/activity';
export default {
  name: 'prizelist',
  data() {
    return {
      acName: '',
      scrollMax: 300,
      navClass: '',
      activityId: 0,
      promiseTimer: '', // 倒计时定时器
      prizeList: []
    };
  },
  computed: {
    dataExtra() {
      const extra = this.prizeList.length % 3;
      return extra === 0 ? 0 : 3 - extra;
    }
  },
  created() {
    // 从url中获取活动相关参数
    const activeId = getUrlKey('activeId');
    // console.log('activeId=' + activeId)
    this.activityId = activeId;
  },
  mounted() {
    // 设置滚动渐变效果
    const stepTotal = 30;
    const stepHeight = this.scrollMax / stepTotal;
    this.$el.addEventListener('scroll', e => {
      if (this.$el.scrollTop >= this.scrollMax - 2) {
        this.navClass = `step${stepTotal - 1}`;
      } else {
        this.navClass = `step${Math.floor(
          stepTotal - (this.scrollMax - this.$el.scrollTop) / stepHeight
        )}`;
      }
    });
    // 加载数据
    this.getPrizeList(this.activityId);
  },
  methods: {
    jumpToAllUser() {
      // 跳转用户查询页面
      this.$router.push({
        path: '/selectall',
        query: {
          activeId: this.activityId,
          acName: this.acName,
          ts: Date.now()
        }
      });
    },
    jumpToPrizedetail(prizeId, luckCode) {
      // 跳转奖品详情页
      this.$router.push({
        path: '/acdetail',
        query: {
          activeId: this.activityId,
          prizeId: prizeId,
          luckCode: luckCode,
          ts: Date.now()
        }
      });
    },
    goBack() {
      // 返回上一页
      // this.$router.push({ path: '/', query: { ts: Date.now() }})
      this.$router.go(-1);
    },
    getPrizeList(activityId) {
      const _this = this;
      const now = getGMT8Date();
      let countDown = false;
      getActivity(activityId)
        .then(res => {
          let data = this.handleResponse(res);
          if (data.code === 200) {
            data = data.data;
            // this.activityInfo = data
            _this.prizeList.length = 0;
            _this.acName = data.activityName;
            data.prizes.every(function(e) {
              // e.openTime = '2021-05-21 00:00:00'
              const prize = {
                // prizeName: e.prizeName,
                // balance: e.balance,
                // sponsor: e.sponsor,
                // picUrl: e.picUrl, // 图片地址
                // grade: e.grade, // 奖品等级
                // id: e.id, // 奖品ID
                // openTime: e.openTime, // 开奖时间
                ...e,
                h: '01',
                m: '02',
                s: '03',
                openStatus: true, // true:已开奖；false:未开奖
                before24H: false // 开奖时间比当前时间晚超 24 小时，显示 'xx 月 xx 日 自动开奖'
              };
              // console.log('奖品：', prize)
              const flag = now < Date.parse(e.openTime.replace(/-/g, '/'));
              if (flag) {
                // 未开奖
                prize.openStatus = false;
                countDown = true;
              }
              const remainTime = parseInt(
                (Date.parse(prize.openTime.replace(/-/g, '/')) -
                  getGMT8Date()) /
                  1000
              );
              prize.h =
                Math.floor((remainTime / 3600) % 24) +
                Math.floor(remainTime / 3600 / 24) * 24;
              prize.m = Math.floor((remainTime / 60) % 60);
              prize.s = Math.floor(remainTime % 60);

              if (parseInt(prize.h) >= 24) {
                prize.before24H = true;
              }
              // 超过 24 小时
              prize.year = e.openTime.substr(0, 4);
              // console.log(prize.year, '年')
              prize.month = e.openTime.substr(5, 2);
              // console.log(prize.month, '月')
              prize.day = e.openTime.substr(8, 2);
              // console.log(prize.day, '日')
              prize.hour = e.openTime.substr(11, 2);
              // console.log(prize.hour, '时')
              prize.min = e.openTime.substr(14, 2);
              // console.log(prize.min, '分')
              prize.sec = e.openTime.substr(17, 2);
              // console.log(prize.sec, '秒')
              // }
              // }
              _this.prizeList.push(prize);
              return true;
            });
          }
          // console.log(_this.prizeList)
          if (countDown) {
            this.countDown();
          }
        })
        .catch(err => {
          this.$message({
            message: '查询奖品数据出错啦！！！',
            customClass: 'ballMsg',
            type: 'error',
            duration: 3000,
            center: true,
            offset: 300
          });
          console.log(err);
        });
    },
    countDown() {
      const self = this;
      clearInterval(this.promiseTimer);
      let stopCount = 0;
      this.promiseTimer = setInterval(function() {
        self.prizeList.every(function(value) {
          let hour = parseInt(value.h);
          let min = parseInt(value.m);
          let sec = parseInt(value.s);
          if (hour === 0) {
            if (min !== 0 && sec === 0) {
              sec = 59;
              min -= 1;
            } else if (min === 0 && sec === 0) {
              sec = 0;
              // clearInterval(self.promiseTimer)
              value.openStatus = true;
              stopCount++;
            } else {
              sec -= 1;
            }
          } else {
            if (min !== 0 && sec === 0) {
              sec = 59;
              min -= 1;
            } else if (min === 0 && sec === 0) {
              hour -= 1;
              min = 59;
              sec = 59;
            } else {
              sec -= 1;
            }
          }
          value.h = self.formatNum(hour);
          value.m = self.formatNum(min);
          value.s = self.formatNum(sec);
          return true;
        });
      }, 1000);
      // 奖品均已开奖，清除定时任务
      if (stopCount === self.prizeList.length) {
        clearInterval(self.promiseTimer);
      }
    },
    formatNum(num) {
      return num < 10 ? '0' + num : '' + num;
    }
  }
};
