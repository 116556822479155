<template>
  <div class="container">
    <div class="header-containter">
      <div class="header-bg" />
      <div class="navbar" :class="navClass">
        <div class="nav-title">
          <!-- <span>首页</span> -->
          <i class="go-back-btn el-icon-arrow-left" @click="goBack" />
        </div>
        <div class="nav-acname">
          活动奖品列表
        </div>
        <div class="nav-btn-containter">
          <span @click="jumpToAllUser">中奖查询</span>
        </div>
      </div>
      <header id="header" class="header">
        <span class="header-desc">{{ acName }}</span>
      </header>
    </div>

    <div class="list-containter">
      <div v-if="prizeList && prizeList.length > 0" class="list-content">
        <div
          v-for="(item, index) in prizeList"
          :key="index + 'item'"
          class="item"
        >
          <div class="item-img">
            <img :src="item.picUrl" alt="img" />
          </div>
          <div class="item-content">
            <div class="item-title">
              <div class="summary">
                <div class="prize-name">
                  <span class="name">奖品：{{ item.prizeName }}</span>
                  <div class="prize-count">
                    <img
                      class="prize-ic"
                      src="../../assets/prizelist/gif.png"
                      alt=""
                    />
                    x<span class="prize-balance">{{ item.balance }}</span>
                  </div>
                </div>
              </div>
              <div class="timer">
                <div
                  v-if="!item.before24H && !item.openStatus"
                  class="countdown-time"
                >
                  <div class="time-item time-h">
                    <span>{{ item.h }}</span>
                  </div>
                  <!-- <div class="time-split">&#58;</div> -->
                  <div class="time-split">时</div>
                  <div class="time-item time-m">
                    <span>{{ item.m }}</span>
                  </div>
                  <div class="time-split">分</div>
                  <div class="time-item time-s">
                    <span>{{ item.s }}</span>
                  </div>
                  <div class="time-split">秒</div>
                  <div class="countdown-title">后开奖</div>
                </div>
                <div
                  v-if="!item.before24H && item.openStatus"
                  class="countdown-time"
                >
                  <div
                    v-if="new Date().getFullYear() + '' !== item.year"
                    class="time-item"
                  >
                    <span>{{ item.year }}</span>
                  </div>
                  <div
                    v-if="new Date().getFullYear() + '' !== item.year"
                    class="time-split"
                  >
                    年
                  </div>
                  <div class="time-item">
                    <span>{{ item.month }}</span>
                  </div>
                  <div class="time-split">月</div>
                  <div class="time-item">
                    <span>{{ item.day }}</span>
                  </div>
                  <div class="time-split">日</div>
                  <div class="time-split">
                    {{ item.hour }}:{{ item.min }}:{{ item.sec }}
                  </div>
                  <div v-if="item.openStatus" class="prize-timerover">
                    已开奖
                  </div>
                </div>
                <div v-if="item.before24H" class="date-time">
                  <div
                    v-if="new Date().getFullYear() + '' !== item.year"
                    class="time-item"
                  >
                    <span>{{ item.year }}</span>
                  </div>
                  <div
                    v-if="new Date().getFullYear() + '' !== item.year"
                    class="time-split"
                  >
                    年
                  </div>
                  <div class="time-item">
                    <span>{{ item.month }}</span>
                  </div>
                  <div class="time-split">月</div>
                  <div class="time-item">
                    <span>{{ item.day }}</span>
                  </div>
                  <div class="time-split">日</div>
                  <div class="time-split">
                    {{ item.hour }}:{{ item.min }}:{{ item.sec }}
                  </div>
                  <!--<div v-if="item.openStatus" class="prize-timerover">已开奖</div>-->
                  <div class="countdown-title">自动开奖</div>
                </div>
              </div>
              <div class="grade">
                <div class="grade-content">
                  <div class="icon">
                    <img src="../../assets/grade.png" alt="等级" />
                  </div>
                  <div class="text">
                    <span>{{ item.grade }}</span>
                  </div>
                </div>
              </div>
              <div class="provider">
                <span class="sponsor">{{ item.sponsor }}</span
                >赞助
              </div>
              <div
                class="detail-btn"
                @click="jumpToPrizedetail(item.id, item.luckCode)"
              >
                <span>查看详情</span>
              </div>
            </div>
          </div>
        </div>
        <template v-for="(item, index) in dataExtra">
          <div :key="index + 'empty'" class="item empty" />
        </template>
      </div>
      <div v-else class="list-content">
        <div class="emptyInfo">
          数据被外星人偷走啦！
          <span class="gobackBtn" @click="goBack">返回</span>
        </div>
      </div>
      <div v-if="prizeList && prizeList.length > 0" class="bottom-desc">
        人家是有底线的~
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$headerHeight: 200px;
$navHeight: 40px;
$navFontSize: 12px;
$headerDescFontSize: 25px;

.container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  // overflow: hidden;
  overflow-y: scroll;
  background-color: #f4f4f4;
  // display: flex;
  // flex-direction: column;

  .header-containter {
    background: url('../../assets/prizelist/header-bg.png');
    background-size: auto 100%;
    background-position: center center;
    position: relative;
    height: $headerHeight;
  }

  .list-containter {
    width: 100%;
    // flex: 1;
    position: relative;
    margin: -50px auto auto auto;
    // min-height: $headerHeight;
  }
}
.header-containter {
  .header-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .navbar {
    z-index: 9;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    height: $navHeight;
    position: fixed;
    display: flex;
    color: #fff;
    justify-content: space-between;
  }
  .nav-acname {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $navFontSize;
    width: 50%;
  }
  $stepTotal: 30;
  @mixin steploop($i) {
    .navbar.step#{$i} {
      $op: $i / ($stepTotal - 1);
      background-color: rgba(6, 12, 12, round($op * 10000) / 10000);
    }
  }
  @for $i from 0 through $stepTotal {
    @include steploop($i);
  }
  .header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $navHeight 8% 10px 8%;
    box-sizing: border-box;
    position: relative;
    .header-desc {
      margin-top: -10%;
      text-align: center;
      font-family: PingFangSC-Regular;
      font-size: $headerDescFontSize;
      color: #fff;
      font-weight: bold;
      font-family: serif;
    }
  }
}
.navbar {
  .nav-name {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: $navFontSize;
    width: 100%;
  }
  .nav-title,
  .nav-btn-containter {
    width: 120px;
    height: 100%;
    display: inline-block;
    span:hover {
      color: #1ab4ab;
      cursor: pointer;
    }
  }
  .nav-title {
    display: flex;
    align-items: center;
    color: #fff;
    // font-weight: bold;
    font-size: $navFontSize;
  }
  .nav-btn-containter {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
    // font-weight: bold;
    font-size: $navFontSize;
  }
  .go-back-btn {
    font-size: $navFontSize;
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    &:hover {
      color: #1ab4ab;
    }
  }
}
// 奖品元素
.list-containter {
  .list-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .item {
    // margin-bottom: 50px;
    max-width: 300px;
    position: relative;
    background-color: #fff;
    margin: 0 5% 20px 5%;
    width: 85%;
    padding: 20px 20px 80px 20px;
    border-radius: 4px;
    box-sizing: border-box;
    &.empty {
      background-color: transparent;
    }
  }

  // 提示信息
  .bottom-desc {
    // position: absolute;
    // : 0;
    display: inline-block;
    width: 100%;
    margin: 10px auto;
    text-align: center;
    font-size: 8px;
    color: #ababab;
  }
  .emptyInfo {
    margin: 0 auto;
    position: absolute;
    box-sizing: border-box;
    top: 80px;
    text-align: center;
    font-size: 12px;
    color: #ababab;
    .gobackBtn {
      text-decoration: underline;
      color: #1ab4ab;
      cursor: pointer;
    }
  }
}

// 奖品小块块
.item {
  .item-img {
    text-align: center;
    margin-bottom: 8px;
    img {
      max-width: 100%;
      height: 90px;
    }
  }
  .prize-name {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 13px;
    font-weight: bold;
    font-family: PingFangSC-Semibold;
  }
  .prize-balance {
    margin-left: 2px;
    color: #1ab4ab;
  }
  .prize-count {
    margin-left: 10px;
    display: flex;
    align-items: center;
    img {
      margin-right: 2px;
      width: 11px;
    }
  }
  .timer {
    margin-top: 15px;
    .countdown-time,
    .date-time {
      font-family: PingFangSC-Regular;
      display: flex;
      align-items: center;
      font-size: 11px;
      line-height: 11px;
      color: #333;
      .prize-timerover {
        color: #f00;
        margin-left: 2px;
      }
    }
    .time-item {
      font-weight: bold;
      color: #1ab4ab;
    }
    .time-split {
      margin: 0 1px;
      line-height: 10px;
    }
  }
  .grade {
    position: absolute;
    bottom: 50px;
    font-family: PingFangSC-Regular;
    display: flex;
    align-items: center;
    .grade-content {
      border: 0.2px solid #1ab4ab;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
    }
    .icon {
      height: 100%;
      box-sizing: border-box;
      padding: 1px 4px;
      background-color: #1ab4ab;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 100%;
        width: 12px;
      }
    }
    .text {
      height: 100%;
      box-sizing: border-box;
      padding: 1px 4px 1px -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 8px;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
  .provider {
    position: absolute;
    bottom: 30px;
    font-family: PingFangSC-Regular;
    font-size: 11px;
    color: #888;
    .sponsor {
      color: #1ab4ab;
      display: inline-block;
      margin-right: 4px;
    }
  }
  .detail-btn {
    position: absolute;
    bottom: 30px;
    right: 20px;
    border-radius: 2px;
    width: 55px;
    line-height: 25px;
    color: #fff;
    font-size: 10px;
    text-align: center;
    background-color: #1ab4ab;
    &:hover {
      cursor: pointer;
      background-color: #169c94;
    }
  }
}
@media screen and (min-width: 1024px) and (min-device-width: 1024px) {
  .container {
    .list-containter {
      max-width: 840px;
    }
  }
  .list-content {
    .item {
      width: 30%;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
</style>

<script>
import prizelist from './prizelist.js';
export default prizelist;
</script>
